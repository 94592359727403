import Cleave from "cleave.js";

let simpleTimePickerWidgetsAreInitialized = false;

document.addEventListener("DOMContentLoaded", () => {
  if (!simpleTimePickerWidgetsAreInitialized) {
    document.querySelectorAll(".simple_time_picker").forEach((el) => {
      // eslint-disable-next-line no-new
      new Cleave(el, {
        time: true,
        timePattern: ["h", "m"],
      });
    });
    simpleTimePickerWidgetsAreInitialized = true;
  }
});
